import { XF } from "./js/XF";

const elRR = document.getElementById("thread-recommended-reading");
let elThreads = elRR?.querySelector(".california-thread-list");

if (!elRR || !elThreads)
  throw new Error("Could not find recommended reading elements");

async function fetchThreads() {
  if (!elThreads || !elRR) return;
  const data = await $.get("/similar-threads", {
    threadId: elRR?.dataset.threadId,
  });
  elThreads.outerHTML = data.html.content;
  elThreads = elRR.querySelector(".california-thread-list");
  if (!elThreads) {
    elRR.remove();
    return;
  }
  XF.activate(elRR);
}

const observer = new IntersectionObserver(async (entries) => {
  if (entries.some((i) => i.isIntersecting)) {
    observer.disconnect();
    try {
      await fetchThreads();
    } catch (err) {
      console.error("Failed to load recommended reading", err);
      if (!elThreads)
        throw new Error("Could not find recommended reading threads");
      elThreads.setAttribute("aria-busy", "false");
      elThreads.textContent = XF.phrase(
        "oops_we_ran_into_some_problems_more_details_console"
      );
    }
  }
});
observer.observe(elRR);
